/* eslint-disable no-unused-expressions */
/* eslint-disable linebreak-style */
import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import DatePicker from '@material-ui/lab/DatePicker';
import TimePicker from '@material-ui/lab/TimePicker';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import momentTimezone from 'moment-timezone';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import styled from 'styled-components';

import { FlatButton } from '../../../../../app/components/buttons';

import '../../../../ecommerce/modules/bookings/components/addAppointment/styles.css';

import ImageUpload from '../../../../../modules/editor/internals/imageUpload/';
import firebase from '../../../../../firebase';
import { getRandomColor } from '../../../../../utils/color';

import { HotelTherapyContext } from '../context/HotelTherapyContext';
import { PGMOL_APPS } from '../../../../../config';
import { objectToArray } from '../../../../../utils/object';

const today = Date.now();

const WrapperDiv = styled.div`
  margin-top: 30px;
  max-width: 324px;
  margin-bottom: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : '40px'};
`;

const StyledIconButton = styled(IconButton)`
  padding-top: 9px;
  &:hover {
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
  }
  &.Mui-focusVisible {
    background-color: transparent !important;
  }
`;

const StyledDeleteButton = styled(IconButton)`
  margin-top: 20px !important;
  &:hover {
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
  }
  &.Mui-focusVisible {
    background-color: transparent !important;
  }
`;

const HotelTherapyModal = props => {
  const {
    open,
    toogleHotelTherapyModal,
    appname,
    hotelTherapyItemChange,
    hotelTherapyItem,
    addItemToHotelTherapy,
    updateItemFromHotelTherapy,
    getHotels,
    getTherapists,
    therapists,
    hotels,
    isLoading,
    clubs,
  } = props;

  const { t } = useTranslation();
  const timezones = momentTimezone.tz.names();
  const formattedClubs =
    clubs &&
    objectToArray(clubs).map(
      item =>
        item.value.location && {
          label: item.value.location,
          value: item.value.location,
        },
    );

  const isUpdate = Object.keys(hotelTherapyItem).length > 0;
  const accountId = useSelector(state => state.account.organisation.key);
  const isImageUploaded = useSelector(
    state => state.ecommerce.inventory.isImageUploaded,
  );
  const getImgUrl = () =>
    `/pgmol/tempImages/hotel_therapies/${appname}/${accountId}`;

  const {
    match,
    setMatch,
    description,
    setDescription,
    capacity,
    setCapacity,
    date,
    setDate,
    key,
    setKey,
    isButtonDisabled,
    setIsButtonDisabled,
    setBookingType,
    appointmentDuration,
    timezone,
    setTimezone,
    setAppointmentDuration,
    selectedStartDate,
    setSelectedStartDate,
    timeslots,
    setTimesloTs,
    bookingType,
    appointmentError,
    setAppointmentError,
    setSlotsError,
    imageUrl,
    setImageUrl,
    setSubmitted,
    appointmentType,
    setAppointmentType,
    hotel,
    setHotel,
    therapist,
    setTherapist,
    address,
    setAddress,
    club,
    setClub,
    addTimeSlot,
    removeTimeSlot,
    updateTimeSlot,
    isDuplicate,
    setIsDuplicate,
  } = useContext(HotelTherapyContext);

  useEffect(() => {
    getHotels();
    getTherapists();
  }, []);

  useEffect(() => {
    if (PGMOL_APPS.includes(appname)) {
      setTimezone({
        value: 'Europe/London',
        label: 'Europe/London',
      });
    }
  }, [appname]);

  useEffect(() => {
    if (PGMOL_APPS.includes(appname)) {
      setTimezone({
        value: 'Europe/London',
        label: 'Europe/London',
      });
    }
  }, [appname]);

  useEffect(() => {
    if (isUpdate) {
      setAppointmentType(hotelTherapyItem.appointmentType);
      setIsDuplicate(hotelTherapyItem.isDuplicate);
      hotelTherapyItem.match && setMatch(hotelTherapyItem.match);
      hotelTherapyItem.description &&
        setDescription(hotelTherapyItem.description);
      hotelTherapyItem.date && setDate(hotelTherapyItem.date);
      hotelTherapyItem.key && setKey(hotelTherapyItem.key);

      hotelTherapyItem.capacity && setCapacity(hotelTherapyItem.capacity);
      hotelTherapyItem.date &&
        setSelectedStartDate(moment(hotelTherapyItem.date));
      hotelTherapyItem.duration &&
        setAppointmentDuration(hotelTherapyItem.duration);
      hotelTherapyItem.timezone && setTimezone(hotelTherapyItem.timezone);
      hotelTherapyItem.club && setClub(hotelTherapyItem.club);
      hotelTherapyItem.club && setClub(hotelTherapyItem.club);
      hotelTherapyItem.therapist && setTherapist(hotelTherapyItem.therapist);
      hotelTherapyItem.hotel && setHotel(hotelTherapyItem.hotel);
      hotelTherapyItem.hotel && setAddress(hotelTherapyItem.hotel.address);
      hotelTherapyItem.timeslots && setTimesloTs(hotelTherapyItem.timeslots);
      hotelTherapyItem.bookingType &&
        setBookingType(hotelTherapyItem.bookingType);
      hotelTherapyItem.imageUrl && setImageUrl(hotelTherapyItem.imageUrl);
    } else if (!isImageUploaded && !isUpdate) {
      setMatch('');
      setDescription('');
      setDate('');
      setAppointmentDuration(30);
      setTimezone({ value: '', label: '' });
      setTherapist({ value: '', label: '' });
      setClub({ value: '', label: '' });
      setClub({ value: '', label: '' });
      setHotel({ value: '', label: '' });
      setCapacity(1);
      setAddress('');
      setTimesloTs([{ time: new Date(), capacityBooked: 0 }]);
      setBookingType('single');
      setIsDuplicate(false);
    }
  }, [hotelTherapyItem, isUpdate]);

  useEffect(() => {
    const getImageUrl = async () => {
      const imageRef = firebase.database().ref(getImgUrl());
      const snapshot = await imageRef.once('value');
      const value = snapshot.val();
      if (value) setImageUrl(value);
    };

    isImageUploaded && getImageUrl();
  }, [isImageUploaded]);

  const resetTherapyData = async () => {
    await firebase
      .database()
      .ref(getImgUrl())
      .set(null);
    hotelTherapyItemChange({});
    setIsButtonDisabled(false);
    setMatch('');
    setDescription('');
    setDate('');
    setImageUrl(null);
    setAppointmentDuration(30);
    setTimezone({ value: '', label: '' });
    setTherapist({ value: '', label: '' });
    setAddress('');
    setHotel({ value: '', label: '' });
    setCapacity(1);
    setSelectedStartDate(new Date());
    setTimesloTs([{ time: new Date(), capacityBooked: 0 }]);
    setBookingType('single');
    setAppointmentError(null);
  };

  const handleClose = async () => {
    await firebase
      .database()
      .ref(getImgUrl())
      .set(null);
    setImageUrl(null);
    toogleHotelTherapyModal(false);
    hotelTherapyItemChange({});
    resetTherapyData();
    //onResetHotelTherapyData();
  };
  const isAppointmentInvalid =
    (isUpdate && today > date) ||
    isButtonDisabled ||
    match.length === 0 ||
    description.length === 0 ||
    !selectedStartDate ||
    capacity < 0;

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitted(true);
    if (isAppointmentInvalid) {
      return;
    }

    setAppointmentError(null);
    setSlotsError(null);

    const formatDate = moment(selectedStartDate).valueOf();
    // console.log(moment(formatDate).format());
    const timeZoneDate = moment(formatDate).format();

    const formattedTimeSlots = timeslots.map(slot => ({
      time: moment(slot.time).valueOf(),
      capacityBooked: slot.capacityBooked,
    }));

    const payload = {
      appointmentType,
      appname,
      match,
      description,

      key,

      imageUrl,

      ...(timezone && timezone.label !== '' && { timezone }),
      backgroundColor: getRandomColor(),
      ...(timezone &&
        timezone.label !== '' && { timezoneDate: timeZoneDate.split('+')[0] }),
      date: formatDate,

      bookingType,
      isDuplicate: false,
      capacity: parseInt(capacity, 10) || 1,
      duration: appointmentDuration,
      hotel,
      therapist,
      club,
      therapist_email: therapist.email,
      numSlots: 1,
      booked: 0,
      timeslots: formattedTimeSlots,
    };

    console.log('duplicate--->', isDuplicate);
    console.log('payload--->', moment(payload.date));
    console.log('isSame-->', moment(date));
    console.log(
      'condition-->',
      moment(payload.date).isSame(moment(date), 'day'),
    );

    if (
      date &&
      payload.date &&
      !isDuplicate &&
      !moment(payload.date).isSame(moment(date), 'day')
    ) {
      setAppointmentError(t('Changing of date is not permitted.'));
      return;
    }

    setIsButtonDisabled(true);

    if (isUpdate) {
      await updateItemFromHotelTherapy(payload, resetTherapyData);
    } else {
      await addItemToHotelTherapy(payload, resetTherapyData);
    }
  };

  return (
    <Dialog
      data-testid="add-appointment"
      open={open}
      onClose={handleClose}
      autoScrollBodyContent
      disableEnforceFocus={true}
      scroll="paper"
      maxWidth="lg"
    >
      <div className="appointment-modal-content">
        <DialogTitle data-testid="title">
          {isUpdate ? 'Edit Hotel Therapy' : 'Add Hotel Therapy'}
        </DialogTitle>
        <form onSubmit={handleSubmit} data-testid="form">
          <fieldset disabled={isUpdate && today > date}>
            <div className="field-wrapper">
              <div>
                <Trans>Image</Trans>
              </div>
              <ImageUpload
                targetId="appointment_header_image"
                src={imageUrl}
                label="Image"
                caption={t('This image will be displayed for appointments')}
                firebaseUrl={getImgUrl()}
                title={t('Appointment Image')}
                tooltip={t('Minimum image size 240x240 pixels')}
                showCropAreaSizes
              />
            </div>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="name"
                label={'Match'}
                value={match}
                required
                fullWidth
                variant="standard"
                onChange={e => setMatch(e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="description"
                label={t('Description')}
                required
                fullWidth
                variant="standard"
                value={description}
                multiline
                onChange={e => setDescription(e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <FormControl fullWidth>
                <InputLabel id="duration" style={{ left: '-15px' }}>
                  <Trans>Duration</Trans>
                </InputLabel>
                <Select
                  labelId="duration"
                  id="durationSelect"
                  data-testid="durationSelect"
                  variant="standard"
                  value={appointmentDuration}
                  disabled={isUpdate && today > date}
                  onChange={e => setAppointmentDuration(e.target.value)}
                >
                  <MenuItem value={15}>
                    <Trans>15 mins</Trans>
                  </MenuItem>
                  <MenuItem value={30}>
                    <Trans>30 mins</Trans>
                  </MenuItem>
                  <MenuItem value={45}>
                    <Trans>45 mins</Trans>
                  </MenuItem>
                  <MenuItem value={60}>
                    <Trans>1 hr</Trans>
                  </MenuItem>
                  <MenuItem value={120}>
                    <Trans>2 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={180}>
                    <Trans>3 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={240}>
                    <Trans>4 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={300}>
                    <Trans>5 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={360}>
                    <Trans>6 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={420}>
                    <Trans>7 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={480}>
                    <Trans>8 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={540}>
                    <Trans>9 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={600}>
                    <Trans>10 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={660}>
                    <Trans>11 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={720}>
                    <Trans>12 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={780}>
                    <Trans>13 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={840}>
                    <Trans>14 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={900}>
                    <Trans>15 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={960}>
                    <Trans>16 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1020}>
                    <Trans>17 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1080}>
                    <Trans>18 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1140}>
                    <Trans>19 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1200}>
                    <Trans>20 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1260}>
                    <Trans>21 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1320}>
                    <Trans>22 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1380}>
                    <Trans>23 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1440}>
                    <Trans>24 hrs</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="field-wrapper">
              <TextField
                fullWidth
                type="number"
                required
                id="capacity"
                label={t('Capacity')}
                variant="standard"
                value={capacity}
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                error={!capacity || capacity <= 0}
                helperText={
                  (!capacity || capacity <= 0) &&
                  t('Capacity has to be greater than 0')
                }
                onChange={e => setCapacity(parseInt(e.target.value, 10))}
              />
            </div>

            <div className="field-wrapper">
              <FormControl fullWidth>
                <InputLabel id="bookingType" style={{ left: '-15px' }}>
                  <Trans>Therapy Type</Trans>
                </InputLabel>
                <Select
                  labelId="bookingType"
                  id="bookingTypeSelect"
                  variant="standard"
                  value={bookingType}
                  disabled={isUpdate && today > date}
                  onChange={e => setBookingType(e.target.value)}
                >
                  <MenuItem value="single">
                    <Trans>Single Slot</Trans>
                  </MenuItem>
                  <MenuItem value="multiple">
                    <Trans>Multiple Slots</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {bookingType === 'single' ? (
              <div className="field-wrapper">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    renderInput={props => (
                      <TextField fullWidth variant="standard" {...props} />
                    )}
                    disableOpenPicker={isUpdate && today > date}
                    label={'Date & Time'}
                    value={selectedStartDate}
                    minDate={moment(new Date())}
                    ampm={false}
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={value => {
                      setSelectedStartDate(value);
                    }}
                  />
                </LocalizationProvider>
              </div>
            ) : (
              <>
                <div className="field-wrapper">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      renderInput={props => (
                        <TextField fullWidth variant="standard" {...props} />
                      )}
                      disableOpenPicker={isUpdate && today > date}
                      label={'Date'}
                      value={selectedStartDate}
                      minDate={moment(new Date())}
                      inputFormat="DD/MM/YYYY"
                      onChange={value => {
                        setSelectedStartDate(value);
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className="field-wrapper" style={{ margin: '0px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '16px',
                    }}
                  >
                    <h4
                      style={{
                        margin: 0,
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 500,
                      }}
                    >
                      Time Slots
                    </h4>
                    <StyledIconButton
                      color="primary"
                      onClick={addTimeSlot}
                      disabled={
                        (isUpdate && today > date) || timeslots.length >= 4
                      }
                    >
                      <AddIcon />
                    </StyledIconButton>
                  </div>

                  {timeslots.map((slot, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          renderInput={props => (
                            <TextField
                              fullWidth
                              variant="standard"
                              {...props}
                              style={{ marginRight: '8px' }}
                            />
                          )}
                          label={`Time Slot ${index + 1}`}
                          value={moment(slot.time)}
                          ampm={false}
                          disabled={isUpdate && today > date}
                          onChange={value => {
                            updateTimeSlot(index, value);
                          }}
                        />
                      </LocalizationProvider>
                      {timeslots.length > 1 && (
                        <StyledDeleteButton
                          onClick={() => removeTimeSlot(index)}
                          disabled={isUpdate && today > date}
                        >
                          <DeleteIcon />
                        </StyledDeleteButton>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="field-wrapper">
              <Autocomplete
                options={formattedClubs}
                getOptionLabel={option => option.label}
                value={club}
                onChange={(event, newValue) => {
                  setClub(newValue);
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    className="autocomplete-field"
                    {...params}
                    label="Select Club"
                    variant="outlined"
                    required
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        border: '0px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                      },
                      endAdornment: null,
                    }}
                  />
                )}
              />
            </div>

            <div className="field-wrapper">
              <Autocomplete
                options={hotels}
                getOptionLabel={option => option.label}
                value={hotel}
                onChange={(event, newValue) => {
                  setHotel(newValue);
                  setAddress(newValue.address);
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    className="autocomplete-field"
                    {...params}
                    label="Select Hotel"
                    variant="outlined"
                    required
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        border: '0px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                      },
                      endAdornment: null,
                    }}
                  />
                )}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="name"
                label="Hotel Address"
                value={address && address.replace(/\n/g, ' ')}
                required
                fullWidth
                variant="standard"
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="field-wrapper">
              <Autocomplete
                options={therapists}
                getOptionLabel={option => option.label}
                value={therapist}
                onChange={(event, newValue) => {
                  setTherapist(newValue);
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    className="autocomplete-field"
                    {...params}
                    label="Select Therapist"
                    variant="outlined"
                    required
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        border: '0px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                      },
                      endAdornment: null,
                    }}
                  />
                )}
              />
            </div>
            {(!isUpdate || (isUpdate && today < date)) && (
              <div>
                <FlatButton
                  label={
                    isLoading
                      ? t('SAVING...')
                      : isUpdate
                      ? t('Update')
                      : t('ADD')
                  }
                  type="submit"
                  primary
                />
              </div>
            )}
            {appointmentError && (
              <span style={{ color: 'red', margin: '3vh 0' }}>
                <Trans>Error</Trans> - {appointmentError}
              </span>
            )}
          </fieldset>
        </form>
      </div>
    </Dialog>
  );
};

export default HotelTherapyModal;
