import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import LinearProgress from 'material-ui/LinearProgress';
import { Trans, useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import {
  get,
  set,
  reduce,
  keys,
  pickBy,
  isEmpty,
  size,
  groupBy,
  mapValues,
} from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
  Collapse,
  Typography,
  Chip,
  Avatar,
  Checkbox,
} from '@material-ui/core';
import { downloadCSV } from 'utils';
import { Empty as EmptyIcon } from 'app/components/icons';
import Search from 'app/components/search';
import ActionMenu from 'app/components/actionMenu';
import styles from '../../../../ecommerce/modules/bookings/components/appointmentsTable/styles.module.css';
import themeStyles from '../../../../../_export.scss';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },

    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const today = Date.now();

const HotelTherapyTable = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    toogleHotelTherapyModal,
    hotelTherapyItemChange,
    loading,
    getAllHotelTherapies,
    getAllBookings,
    error,
    hotelTherapies,
    bookings,
    isLoading,
    deleteHotelTherapy,
    duplicateHotelTherapy,
  } = props;

  const [appointmentData, setAppointmentData] = useState({});
  const [bookingData, setBookingData] = useState({});

  const [searchText, setSearchText] = useState('');
  const [dateOrderFilter, setDateOrderFilter] = useState('desc');
  const [appointmentStatusFilter, setAppointmentStatusFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');

  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getAllHotelTherapies();
    getAllBookings();
  }, []);

  /** 
  ### Sort appointments by date order 
  * @param {{order:string, bookings:object}} 
  * @returns {object} sorted appointments object
  **/
  const orderAppointmentsByDate = ({ order, appointments }) =>
    Object.fromEntries(
      Object.entries(appointments).sort(([, apt1], [, apt2]) =>
        order === 'asc' ? apt1.date - apt2.date : apt2.date - apt1.date,
      ),
    );

  const getCsvData = (selected, chunks) => {
    const csvColumns = [
      'Match',
      'Hotel',
      'Therapist',
      'Club',
      'Date/Time',
      'Duration',
      'Status',
    ];
    let data = `${csvColumns.join(',')}\n`;
    selected.forEach(key => {
      data += `${chunks[key].match || '-'},${chunks[key].hotel.name ||
        '-'},${chunks[key].therapist.name || '-'},${(chunks[key].club &&
        chunks[key].club.value) ||
        '-'},${moment(get(chunks[key], 'date', 0)).format(`MM/DD/YY`)} ${moment(
        get(chunks[key], 'date', 0),
      ).format('HH:mm ')},${`${chunks[key].duration} min` || 'NA'},${
        today < chunks[key].date ? t('Active') : t('Expired')
      }\n`;
    });
    return data;
  };

  useEffect(() => {
    if (hotelTherapies) {
      const orderedAppointmentsData = orderAppointmentsByDate({
        order: dateOrderFilter,
        appointments: hotelTherapies,
      });
      setAppointmentData(orderedAppointmentsData);
    } else setAppointmentData({});
  }, [hotelTherapies]);

  useEffect(() => {
    if (bookings) {
      // Step 1: Group booking data by appointmentDetail.key
      const items = JSON.parse(JSON.stringify(bookings));
      const grouped = groupBy(items, item => item.appointmentDetail.key);

      // Step 2: Map to get the count of items for each key
      const result = mapValues(grouped, group => group.length);

      setBookingData(result);
    } else setBookingData({});
  }, [bookings]);

  useEffect(() => {
    hotelTherapies && handleApplyFilter();
  }, [searchText, timeFilter, dateOrderFilter, appointmentStatusFilter]);
  const handleApplyFilter = async () => {
    let timeValue = [0, 'days'];
    let pastDate = 0;

    if (timeFilter && timeFilter !== 'all') {
      timeValue = timeFilter.split(':');
      pastDate = moment()
        .subtract(timeValue[0], timeValue[1])
        .valueOf();
    }

    const appointments = Object.entries(hotelTherapies);
    const res = appointments.filter(appointment => {
      const timeFilter = pastDate < appointment[1].date;
      const searchFilter =
        appointment[1].match.toLowerCase().includes(searchText.toLowerCase()) ||
        appointment[1].description
          .toLowerCase()
          .includes(searchText.toLowerCase());
      let statusFilter = true;

      if (appointmentStatusFilter === 'active') {
        statusFilter = today < appointment[1].date;
      } else if (appointmentStatusFilter === 'expired') {
        statusFilter = today > appointment[1].date;
      }
      return timeFilter && (searchFilter || !searchText) && statusFilter;
    });

    let filteredAppointmentData = {};
    let orderedAppointmentsData = {};
    if (!isEmpty(res)) {
      filteredAppointmentData = res.reduce(
        (object, [key, value]) => ({ ...object, [key]: value }),
        {},
      );
      orderedAppointmentsData = orderAppointmentsByDate({
        order: dateOrderFilter,
        appointments: filteredAppointmentData,
      });
    }

    setAppointmentData(orderedAppointmentsData);
  };

  useEffect(() => {
    setChunks(appointmentData);
  }, [appointmentData]);

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllAppointments = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllAppointments = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (error) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  const onDeleteSelected = selected => {
    deleteHotelTherapy(selected);
  };
  const onDuplicateSelected = selected => {
    duplicateHotelTherapy(selected);
  };

  const handleEdit = key => {
    toogleHotelTherapyModal(true);
    hotelTherapyItemChange(appointmentData[key]);
  };

  return (
    <div className="scroll-container">
      <div className={`row middle-xs table-heading ${styles.scrollHeader}`}>
        <div className="col-xs-2">
          <span className={styles.scrollHeader}>
            Hotel Therapy: {size(appointmentData)}
          </span>
        </div>
        {!isEmpty(hotelTherapies) ? (
          <>
            <div className="col-xs-6">
              <Search
                name="search"
                placeholder={t('Search...')}
                value={searchText}
                onChange={val => setSearchText(val)}
                onClear={() => setSearchText('')}
              />
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Appointments From</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-date"
                  value={timeFilter}
                  onChange={e => setTimeFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All time</Trans>
                  </MenuItem>
                  <MenuItem value="30:days">
                    <Trans>Past 30 Days</Trans>
                  </MenuItem>
                  <MenuItem value="3:months">
                    <Trans>Past 3 Months</Trans>
                  </MenuItem>
                  <MenuItem value="6:months">
                    <Trans>Past 6 Months</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Status</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-status"
                  value={appointmentStatusFilter}
                  onChange={e => setAppointmentStatusFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All</Trans>
                  </MenuItem>
                  <MenuItem value="active">
                    <Trans>Active</Trans>
                  </MenuItem>
                  <MenuItem value="expired">
                    <Trans>Expired</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {isEmpty(chunks) ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You haven't added any Hotel Therapies yet.</Trans>
          </h5>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <Trans>Click the</Trans>&nbsp;
            <AddCircleIcon />
            &nbsp;button to add a new Hotel Therapy
          </p>
          <p style={{ textAlign: 'center' }}>
            You can add multiple Hotel Therapies which will be displayed in a
            list within the app. Your app users will be able to click on a Hotel
            Therapy, see Hotel Therapiy details and book it.
          </p>
        </div>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          >
            <Table
              stickyHeader
              aria-label="customized table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>Match</TableCell>
                  <TableCell>Hotel</TableCell>
                  <TableCell>Therapist</TableCell>
                  <TableCell width="15%">
                    <Trans>Club</Trans>
                  </TableCell>
                  <TableCell>
                    <div className={styles.dateHeader}>
                      <Trans>Date</Trans>
                      {dateOrderFilter === 'desc' ? (
                        <IconButton
                          onClick={() => setDateOrderFilter('asc')}
                          color="primary"
                          title={t('Sort by oldest')}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => setDateOrderFilter('desc')}
                          color="primary"
                          title={t('Sort by newest')}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  {/* <TableCell>Slots</TableCell> */}
                  <TableCell>
                    <Trans>Duration</Trans>
                  </TableCell>

                  <TableCell>
                    <Trans>Status</Trans>
                  </TableCell>
                  <TableCell>
                    <div className="options-container">
                      <Checkbox
                        disableRipple
                        checked={selectAll || false}
                        onChange={(e, isChecked) =>
                          isChecked
                            ? selectAllAppointments()
                            : deselectAllAppointments()
                        }
                        style={{ color: themeStyles.primaryColor }}
                      />
                      <ActionMenu
                        selected={selected}
                        onDeleteSelected={() => onDeleteSelected(selected)}
                        onDownloadSelected={() => {
                          downloadCSV(
                            getCsvData(selected, chunks),
                            t('appointments'),
                          );
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentData &&
                  Object.keys(chunks).map((key, i) => {
                    return (
                      <>
                        <TableRow
                          hover
                          key={`${key}--${i}`}
                          onClick={() => {
                            handleEdit(key);
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {chunks[key].match || '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {chunks[key].hotel.name || '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {chunks[key].therapist.name || '-'}
                          </TableCell>
                          <TableCell width="15%" component="th" scope="row">
                            {/* <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: 220,
                              }}
                            >
                              {chunks[key].description || '-'}
                            </span>
                          </div> */}
                            {(chunks[key].club && chunks[key].club.value) ||
                              '-'}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {moment(get(chunks[key], 'date', 0)).format(
                              `DD/MM/YY`,
                            )}
                          </TableCell>

                          <TableCell component="th" scope="row" align="center">
                            {`${chunks[key].duration} ${t('min')}` || 'NA'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {(bookingData &&
                              chunks[key].bookingType === 'multiple' &&
                              bookingData[key] ===
                                chunks[key].capacity *
                                  chunks[key].timeslots.length) ||
                            (chunks[key].bookingType !== 'multiple' &&
                              bookingData[key] === chunks[key].capacity) ? (
                              <span className={styles.notAvailable}>
                                <Trans>Fully Booked</Trans>
                              </span>
                            ) : (
                              <>
                                {today < chunks[key].date ? (
                                  <span className={styles.success}>
                                    <Trans>Active</Trans>
                                  </span>
                                ) : (
                                  <span className={styles.error}>
                                    <Trans>Expired</Trans>
                                  </span>
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div className="options-container">
                              <Checkbox
                                disableRipple
                                checked={chunks[key].selected || false}
                                onClick={e => e.stopPropagation()}
                                onChange={e => {
                                  e.stopPropagation();
                                  onSelect(key);
                                }}
                                style={{ color: themeStyles.primaryColor }}
                              />
                              <ActionMenu
                                selected={[key]}
                                onEditSelected={() => handleEdit(key)}
                                onDeleteSelected={() => onDeleteSelected([key])}
                                onDownloadSelected={() =>
                                  downloadCSV(
                                    getCsvData([key], chunks),
                                    t('appointments'),
                                  )
                                }
                                {...(today < chunks[key].date && {
                                  onDuplicateSelected: () =>
                                    onDuplicateSelected([key]),
                                })}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {loading && (
            <div className={styles.bookingLoadingContainer}>
              <div>
                <LinearProgress color="#261ed5" mode="indeterminate" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HotelTherapyTable;
